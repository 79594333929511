<template>
	<div class="container">
		<div class="ewm page-sub-box">
			<div class="flex flex-col gap-y-6 divide-y">
				<div>
					<table class="table_form line-bin">
						<colgroup>
							<col style="width:130px;" />
							<col style="width:auto;" />
						</colgroup>
						<thead class="sub_title_txt">
							<tr>
								<td colspan="4"><h2>생산성평가 기준</h2></td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row" class="whitespace-nowrap">
									<label for="label01">생산성평가기준명 <span class="icon_require">필수항목</span></label>
								</th>
								<td>
									<DxTextBox
										v-model="formData.productionName"
										:max-length="limitNumberTexts.maxLengths.productionName"
										:styling-mode="config.stylingMode"
										class="mr-4"
										:show-clear-button="true"
										:width="310"
										:disabled="config.canModify"
										@key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'productionName')"
									>
										<DxValidator>
											<DxRequiredRule message="생산성평가기준명은 필수입니다." />
										</DxValidator>
									</DxTextBox>
									{{ formData.productionName ? formData.productionName.length : 0 }}/{{
										limitNumberTexts.maxLengths.productionName
									}}자
								</td>
								<th scope="row" class="whitespace-nowrap">
									<label for="label01">생산성 구분 <span class="icon_require">필수항목</span></label>
								</th>
								<td>
									<DxSelectBox
										:data-source="selectBoxDataSource.evalDivisionCd"
										:input-attr="{ 'aria-label': '생산성 구분' }"
										:styling-mode="config.stylingMode"
										v-model="formData.productionTypeCd"
										display-expr="codeNm"
										value-expr="codeId"
										placeholder="구분 선택"
										:width="310"
										class="mr-4"
										:disabled="config.canModify"
										@value-changed="evalDivisionCdSelectionChange"
									>
										<DxValidator>
											<DxRequiredRule message="생산성 구분은 필수입니다." />
										</DxValidator>
									</DxSelectBox>
								</td>
							</tr>
							<tr v-if="formData.productionTypeCd == 1274">
								<th scope="row" class="whitespace-nowrap">
									<label for="label01">IB 건수 가중치 (%)</label>
								</th>
								<td>
									<DxNumberBox
										v-model="formData.inCntWeight"
										:min="0"
										:max="100"
										:styling-mode="config.stylingMode"
										class="mr-4"
										:show-clear-button="true"
										:disabled="config.canModify"
										:width="310"
									>
										<DxValidator>
											<DxRequiredRule message="IB 건수 가중치 (%)은 필수입니다." />
										</DxValidator>
									</DxNumberBox>
								</td>
								<th scope="row" class="whitespace-nowrap">
									<label for="label01">OB 건수 가중치 (%)</label>
								</th>
								<td>
									<DxNumberBox
										v-model="formData.outCntWeight"
										:min="0"
										:max="100"
										:styling-mode="config.stylingMode"
										class="mr-4"
										:show-clear-button="true"
										:disabled="config.canModify"
										:width="310"
									>
										<DxValidator>
											<DxRequiredRule message="OB 건수 가중치 (%)은 필수입니다." />
										</DxValidator>
									</DxNumberBox>
								</td>
							</tr>
							<tr v-else>
								<th scope="row" class="whitespace-nowrap">
									<label for="label01">IB 시간 가중치 (%)</label>
								</th>
								<td>
									<DxNumberBox
										v-model="formData.inTimeWeight"
										:min="0"
										:max="100"
										:styling-mode="config.stylingMode"
										class="mr-4"
										:show-clear-button="true"
										:disabled="config.canModify"
										:width="310"
									>
										<DxValidator>
											<DxRequiredRule message="IB 시간 가중치 (%)은 필수입니다." />
										</DxValidator>
									</DxNumberBox>
								</td>
								<th scope="row" class="whitespace-nowrap">
									<label for="label01">OB 시간 가중치 (%)</label>
								</th>
								<td>
									<DxNumberBox
										v-model="formData.outTimeWeight"
										:min="0"
										:max="100"
										:styling-mode="config.stylingMode"
										class="mr-4"
										:show-clear-button="true"
										:disabled="config.canModify"
										:width="310"
									>
										<DxValidator>
											<DxRequiredRule message="OB 시간 가중치 (%)은 필수입니다." />
										</DxValidator>
									</DxNumberBox>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="flex gap-x-6">
					<div class="w-1/3" v-if="formData.productionTypeCd == 1274">
						<h3 class="text-xl font-medium my-4">기준 범위 (건수)</h3>
						<div>
							<DxDataGrid
								class="grid-box eval-grid"
								key-expr="id"
								ref="kpiProAgtDataGrid"
								:data-source="kpiProAgtDataGridData.dataSource"
								:allow-column-resizing="true"
								:column-resizing-mode="'nextColumn'"
								:show-borders="false"
								:show-column-headers="true"
								:show-column-lines="true"
								:show-row-lines="true"
								:row-alternation-enabled="false"
								:hover-state-enabled="true"
								:word-wrap-enabled="true"
								:no-data-text="this.$_msgContents('CMN_NO_DATA')"
								width="100%"
							>
								<DxLoadPanel :enabled="true" />
								<DxScrolling mode="standard" />
								<DxSorting mode="multiple" />

								<DxColumn
									caption="생산성 기준(건수)ENG"
									data-field="statisticsColumnNameEng"
									:allowEditing="false"
									:allow-sorting="false"
									:visible="false"
								/>
								<DxColumn
									caption="생산성 기준(건수)"
									data-field="statisticsColumnNameKor"
									:allowEditing="false"
									:allow-sorting="false"
									alignment="center"
								/>
								<DxColumn
									caption="적용"
									data-field="apply"
									:allowEditing="false"
									:allow-sorting="false"
									alignment="center"
									cell-template="selectedTemplate"
								/>

								<template #selectedTemplate="{ data }">
									<DxButton
										class="btn_XS white light_filled mr-0"
										:width="50"
										:height="30"
										text="선택"
										type="button"
										styling-mode="contained"
										:disabled="config.canModify"
										@click="onSelectedStatistics(data.row)"
									/>
								</template>
							</DxDataGrid>
						</div>
					</div>
					<div class="w-1/3" v-else>
						<h3 class="text-xl font-medium my-4">기준 범위 (시간)</h3>
						<div>
							<DxDataGrid
								class="grid-box eval-grid"
								key-expr="id"
								ref="kpiProAgtDataGrid"
								:data-source="kpiProAgtDataGridData.dataSource"
								:allow-column-resizing="true"
								:column-resizing-mode="'nextColumn'"
								:show-borders="false"
								:show-column-headers="true"
								:show-column-lines="true"
								:show-row-lines="true"
								:row-alternation-enabled="false"
								:hover-state-enabled="true"
								:word-wrap-enabled="true"
								:no-data-text="this.$_msgContents('CMN_NO_DATA')"
								width="100%"
							>
								<DxLoadPanel :enabled="true" />
								<DxScrolling mode="standard" />
								<DxSorting mode="multiple" />

								<DxColumn
									caption="생산성 기준(시간)ENG"
									data-field="statisticsColumnNameEng"
									:allowEditing="false"
									:allow-sorting="false"
									:visible="false"
								/>
								<DxColumn
									caption="생산성 기준(시간)"
									data-field="statisticsColumnNameKor"
									:allowEditing="false"
									:allow-sorting="false"
									alignment="center"
								/>
								<DxColumn
									caption="적용"
									data-field="apply"
									:allowEditing="false"
									:allow-sorting="false"
									alignment="center"
									cell-template="selectedTemplate"
								/>

								<template #selectedTemplate="{ data }">
									<DxButton
										class="btn_XS white light_filled mr-0"
										:width="50"
										:height="30"
										text="선택"
										type="button"
										styling-mode="contained"
										:disabled="config.canModify"
										@click="onSelectedStatistics(data.row)"
									/>
								</template>
							</DxDataGrid>
						</div>
					</div>
					<div class="w-2/3">
						<h3 class="text-xl font-medium my-4">생산성 평가 방식</h3>
						<div>
							<table>
								<colgroup>
									<col style="width:130px;" />
									<col style="width:auto;" />
									<col style="width:130px;" />
								</colgroup>
								<tbody class="border">
									<tr class="bg-gray-100 border">
										<th scope="row" class="text-lg p-3 text-center border">구분</th>
										<th scope="row" class="text-lg p-3 text-center border">평가방식</th>
										<th scope="row" class="text-lg p-3 text-center">배점</th>
									</tr>
									<tr class="h-14">
										<td v-if="formData.productionTypeCd == 1274" class="text-lg text-center border">통화건수</td>
										<td v-else class="text-lg text-center border">통화시간</td>
										<td>
											<div class="flex items-center gap-x-2">
												<DxTextBox
													v-model="formData.evaluationMethod"
													:styling-mode="config.stylingMode"
													:show-clear-button="true"
													:disabled="config.canModify"
													:readOnly="true"
													height="40"
												>
													<DxValidator>
														<DxRequiredRule message="평가방식은 필수입니다." />
														<DxCustomRule
															:validation-callback="validateEvaluationMethod"
															message="계산식이 유효하지 않습니다."
														/>
													</DxValidator>
												</DxTextBox>
												<DxButton
													text="←"
													:height="30"
													class="btn_XS white filled"
													@click="popEvaluationMethodArray"
													:disabled="config.canModify"
												/>
											</div>
											<div>
												<DxTextBox
													v-model="formData.evaluationMethodId"
													:styling-mode="config.stylingMode"
													:show-clear-button="true"
													:disabled="config.canModify"
													:readOnly="true"
													height="40"
													:visible="false"
												>
													<DxValidator>
														<DxRequiredRule message="평가방식은 필수입니다." />
													</DxValidator>
												</DxTextBox>
											</div>
										</td>
										<td>
											<div>
												<DxNumberBox
													v-model="formData.productionScore"
													:styling-mode="config.stylingMode"
													:disabled="config.canModify"
													:min="0"
													:max="100"
													height="40"
													:input-attr="{ class: 'text-center' }"
												>
													<DxValidator>
														<DxRequiredRule message="배점은 필수입니다." />
													</DxValidator>
												</DxNumberBox>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="mt-4">
								<DxButton
									text="("
									:height="30"
									class="btn_XS white filled"
									@click="onArithmetic('(')"
									:disabled="config.canModify"
								/>
								<DxButton
									text="+"
									:height="30"
									class="btn_XS white filled"
									@click="onArithmetic('+')"
									:disabled="config.canModify"
								/>
								<DxButton
									text="-"
									:height="30"
									class="btn_XS white filled"
									@click="onArithmetic('-')"
									:disabled="config.canModify"
								/>
								<DxButton
									text="*"
									:height="30"
									class="btn_XS white filled"
									@click="onArithmetic('*')"
									:disabled="config.canModify"
								/>
								<DxButton
									text="/"
									:height="30"
									class="btn_XS white filled"
									@click="onArithmetic('/')"
									:disabled="config.canModify"
								/>
								<DxButton
									text=")"
									:height="30"
									class="btn_XS white filled"
									@click="onArithmetic(')')"
									:disabled="config.canModify"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<h2 class="hidden">일반 버튼</h2>
				<div class="bottom-btn-wrap">
					<DxButton
						text="저장"
						class="default filled txt_S medium"
						:disabled="config.canModify"
						:width="120"
						:height="40"
						@click="onSaveFormData"
					/>
					<DxButton text="취소" class="btn_XS white filled txt_S medium" :width="120" :height="40" @click="onCancelFormData" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxValidator, DxCustomRule, DxRequiredRule } from 'devextreme-vue/validator';
import { DxDataGrid, DxColumn, DxLoadPanel, DxScrolling, DxSelection, DxSorting } from 'devextreme-vue/data-grid';
import { formatDate, setCalculateDate, getPastFromToday, isEmpty, isSuccess } from '@/plugins/common-lib';


let vm = this;

export default {
	components: {
		DxButton,
		DxNumberBox,
		DxSelectBox,
		DxTextBox,
		DxValidator,
		DxCustomRule,
		DxRequiredRule,

		DxColumn,
		DxDataGrid,
		DxLoadPanel,
		DxScrolling,
		DxSorting,
	},
	props: {},
	watch: {
		/* 'formData.evaluationMethod'(newValue) {
			if (!newValue) {
				this.formData.evaluationMethodId = '';
			}
		}, */
	},
	data() {
		return {
			allowedKeys: ['+', '-', '*', '/', '(', ')'],
			codeMap: {},
			reqParams: {},
			selectBoxDataSource: {
				evalDivisionCd: [],
			},
			config: {
				canModify: false,
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
			valid: {
				minDate: getPastFromToday(20, 'years'),
				evalFeedbackStartDtMin: getPastFromToday(20, 'years'),
			},
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				contentData: null,
			},
			formData: {
				id: null,
				productionName: null,
				productionTypeCd: 1275,
				evalSheetId: null,
				inCntWeight: 0,
				outCntWeight: 0,
				inTimeWeight: 0,
				outTimeWeight: 0,
				evaluationMethod: null,
				evaluationMethodId: null,
				// evaluationMethodKor: '',
				// evaluationMethodEng: '',
				productionScore: 0,
				viewFl: this.$_enums.common.stringUsedFlag.YES.value,
				delFl: this.$_enums.common.stringUsedFlag.NO.value,
				regId: this.$store.getters.getLoginId,
			},
			limitNumberTexts: {
				textLengths: {},
				maxLengths: {
					productionName: 85,
					evaluationMethod: 85,
				},
			},
			kpiProAgtDataGridData: {
				dataSource: [],
			},
			evaluationMethodArray: [],
			evaluationMethodIdArray: [],
		};
	},
	computed: {
		ewmCodes() {
			return this.$_enums.ewm;
		},
	},
	methods: {
		evalDivisionCdSelectionChange(e) {
			if (!e.event) {
				return;
			}

			console.log('e.sele', e.value);
			this.$set(this.formData, 'productionTypeCd', e.value);

			this.resetForm();

			this.productionTypeCdList();
			// this.formData.evaluationMethodKor = '';
			//this.formData.evaluationMethod = '';
		},
		resetForm() {
			this.formData.inCntWeight = 0;
			this.formData.outCntWeight = 0;
			this.formData.inTimeWeight = 0;
			this.formData.outTimeWeight = 0;
			this.formData.evaluationMethod = null;
			this.formData.evaluationMethodId = null;
			this.evaluationMethodArray = [];
			this.evaluationMethodIdArray = [];
		},
		async productionTypeCdList() {
			if (!vm.formData.productionTypeCd) {
				return;
			}

			const payload = {
				actionname: 'EWM_PRODUCTIVITY_EVALCRITERIA_PRODUCTIONTYPECD_LIST',
				data: {
					statisticsColumnTypeCd: vm.formData.productionTypeCd,
				},
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.kpiProAgtDataGridData.dataSource = res.data.data;
			}
		},
		pushEvaluationMethodArray(data) {
			const currentArrayLength = this.evaluationMethodArray.length;
			console.log('currentArrayLength', currentArrayLength);
			console.log('data', data);
			const prevArrayValue = currentArrayLength > 0 ? this.evaluationMethodArray[currentArrayLength - 1] : '';

			if (!this.allowedKeys.includes(data) && currentArrayLength > 0 && !this.allowedKeys.includes(prevArrayValue)) {
				this.evaluationMethodArray.push('+');
				this.evaluationMethodIdArray.push('+');
			}

			this.evaluationMethodArray.push(this.allowedKeys.includes(data) ? data : data.statisticsColumnNameKor);
			this.evaluationMethodIdArray.push(this.allowedKeys.includes(data) ? data : data.id);

			this.formData.evaluationMethod = this.evaluationMethodArray.join('');
			this.formData.evaluationMethodId = this.evaluationMethodIdArray.join('');
		},
		popEvaluationMethodArray() {
			this.evaluationMethodArray.pop();
			this.evaluationMethodIdArray.pop();

			this.formData.evaluationMethod = this.evaluationMethodArray.join('');
			this.formData.evaluationMethodId = this.evaluationMethodIdArray.join('');
		},
		onSelectedStatistics(rowData) {
			//this.formData.evaluationMethod += rowData.data.statisticsColumnNameKor;
			//this.formData.evaluationMethodId += rowData.data.id;
			this.pushEvaluationMethodArray(rowData.data);
		},
		onArithmetic(symbol) {
			this.pushEvaluationMethodArray(symbol);
		},
		isValidExpressionEvaluationMethod(expression) {
			// 공백 제거
			expression = expression.replace(/\s+/g, '');

			console.log('expression', expression);

			// 빈 문자열이면 false
			if (expression.length === 0) {
				return false;
			}

			// 수식이 숫자, 문자, +, -, *, /, (), . 만 포함하는지 확인
			//if (!/^[0-9a-zA-Z+\-*/().]+$/.test(expression)) {
			//	return false;
			//}

			// 괄호의 짝이 맞는지 확인
			let bracketStack = [];
			for (let char of expression) {
				if (char === '(') {
					bracketStack.push(char);
				} else if (char === ')') {
					if (bracketStack.length === 0) {
						return false;
					}
					bracketStack.pop();
				}
			}
			if (bracketStack.length !== 0) {
				return false;
			}

			// 연산자가 연속으로 오는지 확인 (예: ++, ** 등)
			if (/[\+\-\*\/]{2,}/.test(expression)) {
				return false;
			}

			// 숫자 또는 문자와 숫자 또는 문자 사이에 연산자가 없는지 확인 (예: 5A 5B)
			if (/[0-9a-zA-Z]\s+[0-9a-zA-Z]/.test(expression)) {
				return false;
			}

			// 연산자가 시작이나 끝에 오는지 확인
			if (/^[\+\*\/]|[\+\-\*\/]$/.test(expression)) {
				return false;
			}

			// 올바른 소수점 사용 확인 (연속된 소수점 또는 숫자 없이 소수점 사용)
			if (/\.{2,}/.test(expression) || /[^0-9]\.[^0-9]/.test(expression)) {
				return false;
			}

			// 모든 검사를 통과하면 유효한 연산식
			return true;
		},
		validateEvaluationMethod(e) {
			return this.isValidExpressionEvaluationMethod(e.value);
		},
		getConvertEvaluationMethodIdArray() {
			return this.evaluationMethodIdArray.map(m => (this.allowedKeys.includes(m) ? m : `[${m}]`)).join('');
		},
		/** @description : 저장 버튼 클릭 메서드 */
		async onSaveFormData(e) {
			console.log('getConvertEvaluationMethodIdArray', this.getConvertEvaluationMethodIdArray());
			if (!e.validationGroup.validate().isValid) {
				console.log('sdfsdaf');
				return false;
			}

			/* console.log('this.formData.evaluationMethod', this.formData);
			const validEvaluationMethod = this.isValidExpressionEvaluationMethod(this.formData.evaluationMethod);
			console.log('validEvaluationMethod', validEvaluationMethod);

			if (!validEvaluationMethod) {
				return false;
			} */

			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>알림</h2>` }))) {
				return false;
			}

			const data = {
				id: this.formData.id,
				productionName: this.formData.productionName,
				productionTypeCd: this.formData.productionTypeCd,
				inCntWeight: this.formData.inCntWeight == null ? 0 : this.formData.inCntWeight,
				outCntWeight: this.formData.outCntWeight == null ? 0 : this.formData.outCntWeight,
				inTimeWeight: this.formData.inTimeWeight == null ? 0 : this.formData.inTimeWeight,
				outTimeWeight: this.formData.outTimeWeight == null ? 0 : this.formData.outTimeWeight,
				evaluationMethod: this.getConvertEvaluationMethodIdArray() + '|' + this.formData.evaluationMethod,
				productionScore: this.formData.productionScore,
				viewFl: this.formData.viewFl,
			};
			console.log(data);

			let payload = {
				actionname: 'EWM_PRODUCTIVITY_EVALCRITERIA_SAVE',
				data: data,
				loading: true,
			};

			let res = await this.CALL_EWM_API(payload);

			if (res.status === 200) {
				if (res.data.header.resCode === 'success') {
					this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));

					this.onCancelFormData();
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 닫기 버튼 클릭 메서드 */
		onCancelFormData() {
			this.$router.push('/ewm/productivity/implementation/evaluation-criteria');
			//this.createdData();
		},
		/** @description : 셀렉트 박스 초기화 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_kpi_production_type');
		},
		/** @description: 라이프사이클 created시 호출되는 메서드 */
		async createdData() {
			vm = this;

			this.reqParams = this.$store.getters.getDetailParams;
			if (!this.reqParams) {
				this.$_goPrePage();
				return;
			}

			if (!this.$_commonlib.isEmpty(this.reqParams)) {
				console.log('this.reqParams', this.reqParams);
				this.config.canModify = this.reqParams.useFl == 'Y';
				this.formData = this.$_commonlib.cloneObj(this.reqParams);

				if (this.formData.evaluationMethod) {
					this.evaluationMethodArray = this.formData.evaluationMethod
						.split(/([\(\)\/\+])/)
						.map(token => token.trim())
						.filter(token => token !== '');
					console.log('this.evaluationMethodArray', this.evaluationMethodArray);
				}
				if (this.formData.evaluationMethodId) {
					this.evaluationMethodIdArray = this.formData.evaluationMethodId
						.split(/([\(\)\/\+])/)
						.map(token => String(token).trim())
						.filter(token => token !== '');
					console.log('this.evaluationMethodId', this.evaluationMethodIdArray);
				}
			}

			await this.initCodeMap().then(() => {
				this.selectBoxDataSource.evalDivisionCd = this.$_fetchCodesByDepth(this.codeMap['root_ewm_kpi_production_type'], 2);
			});
		},
		mountedData() {
			this.productionTypeCdList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>
